body {
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* ... other styles ... */
  overflow-x: hidden;
}

::selection {
  color: #FFFFFF;
  background: #2FB95D;
}

.font-weight-bold {
  font-weight: 600;
  color: #21143D;
  font-size: 20px;
  line-height: 30px;
  font-family: Mulish;
}

.sub_heading_home {
  font-size: 24px;
  font-weight: 700;
  font-family: Mulish;
  color: #043A3A;
}

.heading_home {
  font-size: 38px;
  font-weight: 800;
  font-family: Mulish;
  color: #043A3A;
}

.heading_blog {
  font-size: 30px;
  font-weight: 800;
  font-family: Mulish;
  color: #FFFFFF;
}

.contact-f {
  background: url('../src/Images/Home/productbackground.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}

.ourproductbackground {
  background-color: rgba(255, 255, 255, 0.8);
  /* Background color with transparency */
  backdrop-filter: blur(10px);
  /* Applies a blur effect to the background */
  border-radius: 30px;
  /* Sets the border radius to 30 pixels, creating rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Adds a subtle shadow for depth */
}

.viewallproduct {
  font-size: 20px;
  font-weight: 500;
  color: #2FB95D;
}

.heading_home_color {
  font-size: 38px;
  font-weight: 800;
  font-family: Mulish;
  color: #2FB95D;
}

.paraheading {
  font-size: 21px;
  font-family: Mulish;
  font-weight: 500;
  color: #043A3A;
}
.homeheading_fer{
  font-size: 15px;
  line-height: 20px;
  font-family: Mulish;
  font-weight: 500;
  color: #043A3A;
}
.paraheading_terms {
  font-size: 16px;
  font-family: Mulish;
  font-weight: 500;
  color: #043A3A;
}

.nav-link {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: Mulish;
  color: #043A3A !important;
}


.gradient-text-navbar {
  background-image: linear-gradient(45deg, #2FB95D, #2FB95D);
  background-size: 100%;
  background-clip: text;
  color: transparent;
  font-size: 20px;
  /* line-height: 30px; */
  font-weight: 700 !important;
  font-family: Mulish;
  margin-top: 8px;
  text-decoration: none;
}

.getapp {
  width: 173px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--Default, #2FB95D);
  display: flex;
  justify-content: center;
  align-items: center;
}

.getapp:hover {
  background-color: white;
  border: 2px solid #2FB95D;
  border-radius: 30px;
  cursor: pointer;
}

.getapp_text:hover {
  color: var(--White, #2FB95D);
  background-color: white;
}

h2.text-center.border_new {
  font-size: 16px;
}

.getapp_text {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.rashaillogotext {
  color: #043A3A;
  text-align: center;
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  /* 106.25% */
  letter-spacing: 0.2px;
}

.rashaillogotext2 {
  color: #2FB95D;
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.2px;
}

.footer_firstdiv {
  width: 1139px;
  height: auto;
  /* Adjusted for vertical responsiveness */
  max-height: 300px;
  /* Set a maximum height if needed */
  position: absolute;
  z-index: 1;
  padding: 20px;
  /* Adjusted for better spacing */
  background-color: #2FB95D;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -120px !important;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;

}

.elevate_footer {
  color: #FFF;
  font-weight: 600;
  font-size: 24px;
  font-family: Mulish;
}

.download_footer {
  font-size: 48px;
  font-weight: 500;
  color: #FFF;
  font-family: Mulish;
}

.explore_footer {
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  font-family: Mulish;
}

.buttonfunctionaity {
  background-color: #2FB95D !important;
  color: #FFFFFF !important;
  width: 230px;
  height: 44px;
  font-family: Poppins;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 30px;
  border-radius: 30px !important;
  border: 2px solid #1388CD;
}

.buttonfunctionaity_footer {
  background-color: #2FB95D !important;
  color: #FFFFFF !important;
  width: 230px;
  height: 44px;
  font-family: Poppins;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 30px;
  border-radius: 30px !important;
  border: 1px solid #FFFFFF !important;
}

.buttonfunctionaity_farming {
  background-color: #2FB95D !important;
  color: #FFFFFF !important;
  width: 173px;
  height: 44px;
  font-family: Poppins;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 30px;
  border-radius: 30px !important;
  border: 2px solid #1388CD;
}

.buttonfunctionaity1 {
  color: #2FB95D !important;
  background-color: #FFFFFF !important;
  font-size: 20px;
  line-height: 30px;
  width: 230px;
  font-family: Poppins;
  font-weight: 500 !important;
  height: 44px;
  border: 2px solid #2FB95D !important;
  border-radius: 30px !important;
}

.buttonfunctionaity_productdetails {
  color: #2FB95D !important;
  background-color: #FFFFFF !important;
  font-size: 20px;
  line-height: 30px;
  width: 230px;
  font-family: Poppins;
  font-weight: 500 !important;
  height: 44px;
  border: 2px solid #2FB95D !important;
  border-radius: 30px !important;
}

img.img-fluid1 {
  margin-left: -26px;
}

.user_2m {
  font-size: 20px;
  font-weight: 700;
  color: #043A3A;
}

.rounded-video {
  border-radius: 30%;
  overflow: hidden;
}

.user_active {
  font-size: 20px;
  font-weight: 500;
  font-family: Mulish;
  color: #9FAFA4;
}

.middle_down_farming {
  background-color: #2FB95D;
  padding-top: 70px;
  padding-bottom: 70px;
}

.forios_heading {
  font-size: 26px;
  font-weight: 700;
  font-family: Mulish;
  color: #043A3A;
}

.circular-button {
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  font-size: 20px;
  background-color: #2FB95D;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.question_faq {
  background: #FFF;
  border-radius: 15px;
  box-shadow: 8px 6px 16px 0px rgba(0, 0, 0, 0.09);
  padding-top: 10px;
  padding-bottom: 10px;
}

.faq_question {
  font-size: 16px;
  font-weight: 700;
  font-family: Mulish;
  color: #043A3A;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin: 10px 0;
}

.faq_answer {
  font-size: 16px;
  font-weight: 500;
  font-family: Mulish;
  color: #043A3A;
}

.card_home_heading {
  font-size: 22px;
  font-weight: 700;
  font-family: Mulish;
  color: #043A3A;
}

.card_home_heading_subpro {
  font-size: 16px;
  font-weight: 700;
  font-family: Mulish;
  color: #043A3A;
}


.custom-card-style {
  border: none !important;
  border-radius: 15px;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  /* Add or adjust background color */
}

.custom-card-style_blog {
  border: none !important;
  border-radius: 15px;
  /* box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.12); */
  background-color: #ffffff;
}

.testimonial-image-container {
  border: 2px solid white;
}

.middle-image img {
  width: 150px;
  /* Adjust width as needed for the middle image */
  height: 150px;
  /* Adjust height as needed for the middle image */
  border-color: green;
}

.other-image img {
  width: 100px;
  /* Adjust width as needed for the first and third images */
  height: 100px;
  /* Adjust height as needed for the first and third images */
}

.leftToRightAnimation {
  transition: transform 0.5s ease-in-out;
}

.leftToRightAnimation:hover {
  transform: translateX(90px);
}

svg.img-fluid1 {
  margin-left: -43px;
}

.footerhead {
  font-size: 20px;
  font-family: Mulish;
  font-weight: 700;
  color: #FFF;
}

.footer-copyright {
  font-size: 14px;
}

.svg_input {
  width: 30px;
  height: 34px;
}

.readmore {
  color: #2FB95D;
  cursor: pointer;
}

.line_heading {
  width: 48px;
  border-top: 2px solid #043A3A;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 8px;
}


.footerlinkheading:hover {
  color: #2FB95D !important;
  text-decoration: underline;
}

.hover-effect:hover {
  color: #2FB95D !important;
  /* Change the color to your desired hover color */
  text-decoration: none;
  /* Optional: Remove underline on hover */
}

.custom-middle-image {
  max-width: 200px;
  /* Adjust this value as needed */
  /* Add any additional styling you want for the middle image */
}

.facebook-mobile:hover {
  color: #2FB95D !important;

}

/* animations.css */

.fadeInLeft-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fadeInLeft-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}

.fadeInLeft-exit {
  opacity: 1;
  transform: translateX(0);
}

.fadeInLeft-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1000ms, transform 1000ms;
}

.footer_input {
  border: 2px solid #043A3A;
  /* Border color */
  padding: 5px;
  /* Padding inside the input */
  width: 200px;
  /* Adjust the width as needed */
  font-size: 16px;
  /* Font size */
  border-radius: 10px 0 0 10px;
  /* Rounded corners on the left top and bottom */
  outline: none;
  /* Remove default focus outline */
}

.footer_input:focus {
  border-color: #2c3e50;
  /* Change border color on focus */
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  /* Add a subtle box shadow on focus */
}

.largevideo iframe {
  border-radius: 30px;
}

.smallvideo iframe {
  border-radius: 15px;
}



.faq_question_container p {
  margin: 0;
  /* Remove default margin for the paragraph inside the container */
}

/* img.footer_playstore.mx-md-2.mb-2 {
  width: 150px;
}
img.footer_playstore.mb-2 {
  width: 150px;
} */
p.description_productdetails {
  font-size: 18px !important;
}

@media (max-width: 1024px) {
  .nav-link {
    font-size: 14px !important;
  }

  .buttonfunctionaity {
    width: 120px;
    height: 37px;
    font-size: 8px !important;
  }

  .buttonfunctionaity1 {
    width: 120px;
    height: 37px;
    font-size: 8px !important;
  }

  .gradient-text-navbar {

    font-size: 14px;

    margin-top: 13px;

  }

  .footer_firstdiv {
    width: 770px;
    /* top: 80vh; */
  }

  .facebook-mobile {
    font-size: 18px !important;
  }

  .card.custom-card-style {
    width: 14rem !important;
  }

  .card.custom-card-style_blog {
    width: 13rem !important;
  }

  .footer-copyright {
    font-size: 12px;
  }

  .custom-card-style {
    width: 16rem !important;
  }

  .custom-card-style_blog {
    width: 16rem !important;
  }

  .card_home_heading {
    font-size: 16px;
  }

  .faq_answer {
    font-size: 12px;
  }

  .forios_heading {
    font-size: 18px;
  }

  .buttonfunctionaity_farming {
    width: 111px;
    font-size: 10px !important;
  }

  .rashailagro_footerlogo {
    width: 204px;
    height: 50px;
  }

  .margin_activeuser {
    margin-left: 60px;
  }

  .followfooter {
    font-size: 16px;
  }

  .footerhead {
    font-size: 16px;
  }

  .sub_heading_home {
    font-size: 18px;
  }

  .paraheading {
    font-size: 16px;
  }

  .heading_home {
    font-size: 30px;
  }

  .heading_home_color {
    font-size: 30px;
  }
}


@media (max-width: 768px) {
  .download_footer {
    font-size: 30px;
  }

  .buttonfunctionaity_farming {
    width: 97px;
    height: 40px;
  }

  .download_farming_home {
    font-size: 24px !important;
  }

  .enhance_home {
    font-size: 22px !important;
  }

  .footer_firstdiv {
    width: 500px;
  }

  .card.custom-card-style {
    width: 13rem !important;
  }

  .card.custom-card-style_blog {
    width: 12rem !important;
  }

  .margin_activeuser {
    margin-left: 110px;
  }


  .facebook-mobile {
    font-size: 10px !important;
  }

  .custom-card-style {
    width: 13rem !important;
  }

  .custom-card-style_blog {
    width: 13rem !important;
  }

  .buttonfunctionaity {
    width: 95px;
    height: 37px;
    font-size: 8px !important;
  }

  .buttonfunctionaity1 {
    width: 95px;
    height: 37px;
    font-size: 8px !important;
  }

  .footer_input {
    width: 150px;
    background-color: #FFF;
  }

  .svg_input {
    width: 30px;
    height: 35px;
  }

  .footer_input::placeholder {
    font-size: 14px;
  }

  .faq_question {
    font-size: 16px;
  }

  .footerlinkheading {
    font-size: 12px;
  }

  .footer-copyright {
    font-size: 10px;
  }

  .followfooter {
    font-size: 12px;
  }

  .footerhead {
    font-size: 12px;
  }

  .footer-address {
    font-size: 12px;
  }

  .sub_heading_home {
    font-size: 12px;
  }

  .paraheading {
    font-size: 12px;
  }

  .forios_heading {
    font-size: 10px;
  }
  .col-md-12.productimg-productdetails.col-lg-6.text-center{
  display:-webkit-flex;
        width: 100%;
  }
  .buttonfunctionaity_productdetails{
    width: 143px;
  }
  .rashailagro_footerlogo{
    width: 150px;
    height: 50px;
  }
}

@media (max-width: 425px) {
  .buttonfunctionaity_farming {
    width: 147px;
    height: 40px;
  }

  .home_empty {
    height: 100px !important;
  }

  .col-md-8.text-center.ourproductbackground {
    width: 80%;
    bottom: -92px !important;
  }

  .contact-f {
    height: 300px;
  }

  .forios_heading {
    font-size: 16px;
  }

  .middle_down_farming {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /* Add this CSS to your stylesheet or component */
  /* Add this to your separate CSS file */


  /* .footer_firstdiv {
    display: none;
  } */
  .footer_firstdiv {
    width: 381px;
  }

  .svg_input {
    width: 29px;
    height: 34px;
  }

  .text-start.sub_heading_home.blog.our {
    text-align: center !important;
  }

  .text-start.heading_home.our {
    text-align: center !important;
  }

  .elevate_footer {
    font-size: 10px;
    text-align: center;
    padding-top: 50px !important;
  }

  /* .mobile_footerappstore {
    padding-bottom: 40px !important;
  } */

  .explore_footer {
    font-size: 10px;
    text-align: center;
  }

  .download_footer {
    font-size: 23px;
  }

  .custom-card-style {
    width: 20rem !important;
  }

  .custom-card-style_mobile {
    width: 20rem !important;
  }

  .card_home_heading {
    font-size: 14px;
  }

  .faq_answer {
    font-size: 12px;
  }

  .faq_question {
    font-size: 14px;
  }

  .sub_heading_home {
    font-size: 15px;
  }

  .paraheading {
    font-size: 16px;
  }

  .user_active {
    font-size: 10px;
  }

  .user_2m {
    font-size: 16px;
    margin-top: 20px !important;
  }

  .paraheading {
    font-size: 14px;
  }

  .footer_input {
    width: 300px !important;
  }

  .buttonfunctionaity {
    width: 100%;
    height: 40px;
    font-size: 14px !important;
  }

  .buttonfunctionaity1 {
    width: 100%;
    height: 40px;
    font-size: 14px !important;
  }

  .getapp {
    width: 116px;
    height: 43px;
  }

  .getapp_text {
    font-size: 11px;
  }

  .enhance_home {
    font-size: 16px !important;
  }

  .buttonfunctionaity_productdetails{
    width: 190px;
  }
  /* .emptydiv {
    display: none;
  } */
}

@media (max-width: 375px) {
  .navbar_logo {
    width: 104px;
    height: 30px;
  }

  .buttonfunctionaity {
    width: 317px !important;
    height: 46px !important;
    font-size: 16px !important;
  }

  .buttonfunctionaity1 {
    width: 317px !important;
    height: 46px !important;
    font-size: 16px !important;
  }

  .user_active {
    font-size: 6px;
  }


  .footer_firstdiv {
    width: 342px !important;
  }

  .buttonfunctionaity_farming {
    width: 127px;
    height: 38px;
  }
}

@media (max-width: 390px) {
  .footer_firstdiv {
    width: 361px !important;
  }
}

@media (max-width: 430px) {
  .footer_firstdiv {
    width: 353px !important;
  }

  .download_footer {
    font-size: 20px;
  }

  .explore_footer {
    font-size: 13px;
  }

  .elevate_footer {
    font-size: 18px;
  }

  .custom-card-style {
    width: 20rem !important;
  }



  .buttonfunctionaity {
    width: 355px;
    height: 52px;
    font-size: 21px !important;
  }

  .buttonfunctionaity1 {
    width: 355px;
    height: 52px;
    font-size: 21px !important;
  }

  .getapp {
    width: 100px;
    height: 48px;
  }

  .getapp_text {
    font-size: 11px;
  }
}

@media (max-width: 820px) {
  .margin_activeuser {
    margin-left: 102px
  }
}

@media (max-width: 280px) {
  .footer_firstdiv {
    width: 260px !important;
  }

  .download_footer {
    font-size: 18px;
  }

  .explore_footer {
    font-size: 14px;
  }

  .elevate_footer {
    font-size: 14px;
  }

  .buttonfunctionaity {
    width: 243px !important;
  }

  .buttonfunctionaity1 {
    width: 243px !important;
  }

  .buttonfunctionaity_farming {
    width: 78px;
  }

  .getapp {
    width: 74px;
    height: 30px;
  }
}

@media (max-width: 540px) {
  .buttonfunctionaity {
    width: 340px;
    height: 40px;
    font-size: 12px !important;
  }

  .buttonfunctionaity1 {
    width: 340px;
    height: 40px;
    font-size: 12px !important;
  }

}


/* switch  */
/* Vertical switch container */
.switch {
  position: relative;
  display: inline-block;
  height: 60px;
  /* Height adjusted for vertical switch */
  width: 34px;
  /* Width adjusted for vertical switch */
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Vertical slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ccc;
  transition: .4s;
}

/* Slider handle */
.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 26px;
  top: 2px;
  left: 3px;
  border: 2px solid black;
  /* Add border property */
  transition: .4s;
}


/* Apply styles when checked */
input:checked+.slider {
  background-color: white;

}

input:focus+.slider {
  /* box-shadow: 0 0 5px white; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

input:checked+.slider:before {
  transform: translateY(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 1px solid #2FB95D;
}

.slider.round:before {
  border-radius: 50%;
}

/* Add this to your CSS file */
.switch-container:hover .slider {
  transform: translateY(-10px);
  /* Adjust the value as needed */
}

/* Add these styles to your CSS file */
/* Add these styles in your CSS file */
.image-hover {
  transition: transform 0.5s ease-in-out;
  transform: translateX(90px);
  /* or any other transform effect you desire */
  -webkit-animation: slideIn 1s forwards;
  -moz-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

/* privacy policy ------------------------------------- */
.mainheading {
  font-size: 32px;
  font-weight: 700;
  font-family: Mulish;
  line-height: 50px;
  color: #2FB95D;
}

.mainheading_terms {
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  line-height: 50px;
  color: #2FB95D;
}

.privacy_heading {
  font-size: 38px;
  font-family: Mulish;
  font-weight: 800;
  color: #2FB95D;
}

.policy_heading {
  font-size: 38px;
  font-family: Mulish;
  font-weight: 800;
  color: #043A3A;
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #2FB95D;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.border_new {
  border-bottom: 1px solid #2FB95D;
  padding-bottom: 10px;
}

.download_farming_home {
  font-size: 36px;
  font-family: Mulish;
  font-weight: 700;
  color: #FFF;
}

.enhance_home {
  font-size: 24px;
  font-weight: 500;
  color: #FFF;
  font-family: Mulish;
}

.video_image {
  border-radius: 30px !important;
  width: 295.33px;
  height: 171.5px;
}

/* iframe#widget10 {
  border-radius: 30px !important;
} */

iframe#widget16 {}


/* blog ----------------------------------------------------------- */
.viewall_blog {
  width: 100px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #2FB95D;
  color: #2FB95D !important;
  justify-content: center;
  font-weight: 700;
  font-family: Mulish;
}

.card_blog_first {
  border-radius: 30px !important;
  background-color: #FFF;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12);
}

.readmore_blog {
  border-radius: 30px;
  background-color: #2FB95D;
  padding-top: 10px;
  /* Adjust the value as needed */
  padding-bottom: 10px;
  color: #FFF;
  font-family: Mulish;
  font-weight: 700;
}

.readmore_blog_subpro {
  border-radius: 30px;
  background-color: #2FB95D;
  padding-top: 10px;
  font-size: 13px;
  /* Adjust the value as needed */
  padding-bottom: 10px;
  color: #FFF;
  font-family: Mulish;
  font-weight: 700;
}

.video_highlight {
  width: 295.33px;
  height: 171.5px;
  flex-shrink: 0;
  border-radius: 30px;
}

.img_community {
  border-radius: 22.5px;
  padding-left: 10px;
  padding-right: 10px;
}

.sonu_color {
  font-size: 18px;
  font-weight: 700;
  color: #2FB95D;
}

.location_color {
  font-size: 15px;
  color: #9FAFA4;
  font-family: Mulish;
  font-weight: 600;
}

.like_blog {
  font-size: 8px;
  font-weight: 700;
  font-family: Mulish;
  color: #9FAFA4;
}

.blog_que {
  font-size: 18px;
  font-weight: 700;
  color: #2FB95D;
  font-family: Mulish;
}

.blog_answer {
  font-size: 18px;
  font-weight: 500;
  color: #043A3A;
  font-family: Mulish;
}

.Farming_community {
  height: 605px;
  border-radius: 0px 0px 50px 50px;
  background: var(--Secondary, #043A3A);
}

.forming_hub {
  margin-top: 100px !important;
}

.inputWithBorder {
  border: 1px dashed var(--Default, #2FB95D) !important;
  border-radius: 30px !important;
  /* width: 753px; */
  height: 93px;
  text-align: center;
}

.selectcategory_blog {
  /* width: 753px !important; */
  height: 93px !important;
  border-radius: 30px !important;
  background: #FFF;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12);
  border: none;
}

.description_blog {
  /* width: 753px !important; */
  border-radius: 30px !important;
  background: #FFF;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12);
  border: none;
}

.submitreview_blog {
  /* width: 753px !important; */
  height: 93px !important;
  border-radius: 30px !important;
  background: #2FB95D !important;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12);
  border: none;
  font-size: 24px !important;
  color: #FFF !important;
  font-family: Mulish;
}

/* 
.addheadline {
  width: 753px;
  height: 93px;
  border-radius: 30px !important;
  background: #FFF;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12) !important;
} */
.addheadline_blog {
  /* width: 753px; */
  height: 93px;
  border-radius: 30px !important;
  background: #FFF;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12);
  border: none !important;
  /* Add this line to remove the border */
}

.all-blog_img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media (max-width: 425px) {
  .card.custom-card-style_blog {
    width: 11rem !important;
  }

  .blog_que {
    font-size: 10px;
  }

  .blog_answer {
    font-size: 10px;
  }
}

@media (max-width: 375px) {
  .card.custom-card-style_blog {
    width: 10rem !important;
  }
}

@media (max-width: 1024px) {
  .card.card_blog_first {
    max-width: 18rem !important;
  }
}



/* About us ------------------------------------------ */
.aboutusparaheading {
  font-size: 24px;
  font-family: Mulish;
  font-weight: 800;
  color: #043A3A;
}

.aboutgreenpartitle {
  font-size: 22px !important;
  font-family: Mulish;
  color: #2FB95D !important;
  font-weight: 600;
}

.aboutgreenpara {
  font-size: 20px;
  font-family: Mulish;
  color: #2FB95D;
}

.background_aboutmiddle {
  background-color: #2FB95D;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cart_aboutus {
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.12);
  border-radius: 30px !important;
  border: none !important;
}

.m_about {
  color: #FFFFFF;
  font-size: 48px;
  font-family: Mulish;
  font-weight: 600;
}

.active_about {
  font-size: 24px;
  color: #FFFFFF;
  font-family: Mulish;
}

.cart_aboutus:hover {
  background-color: #2FB95D;
}

.cart_aboutus:hover .aboutgreenpartitle,
.cart_aboutus:hover .card-text {
  color: white !important;
}

.cart_aboutus:hover img {
  background-color: #FFFFFF;
}

@media (max-width: 1024px) {
  .cart_aboutus {
    width: 16rem !important;
  }

  .aboutgreenpartitle {
    font-size: 18px !important;
  }

  .aboutgreenpara {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .cart_aboutus {
    width: 12rem !important;
  }

  .aboutgreenpartitle {
    font-size: 14px !important;
  }

  .aboutgreenpara {
    font-size: 14px !important;
  }
}

@media (max-width: 425px) {
  .drive2_img_move {
    bottom: -179px !important;

  }

}


/* Features ----------------------------------------------------------- */
.fasalam_bottombackground {
  background: url('../src/Images/Features/Group\ 1000007807.webp');
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 700px; */
}

.backgroundfeaturs_image {
  width: 463px;
  height: 478px;
  border-radius: 50px;
  background-color: #2FB95D;
}


@keyframes slideInfeafures {

  0%,
  100% {
    transform: translateX(-50px);
  }

  50% {
    transform: translateX(20px);
  }
}

.col-md-5.inouteffect_features {
  animation: slideInfeafures 4s linear infinite;
  animation-play-state: running;
}

@keyframes slideInfeafures__right {

  0%,
  100% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(50px);
  }
}

.col-md-5.inouteffect_features_right {
  animation: slideInfeafures__right 4s linear infinite;
  animation-play-state: running;
}

.featuresparaheading {
  font-size: 24px;
  color: #2FB95D;
  font-family: Mulish;
  font-weight: 600;
}

/* carosel ----------------------- */
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.carousel-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-item {
  margin: 10 50px !important;
  transition: transform 0.3s;
  flex: 0 0 50%;
}

.caroselimge {
  max-width: 300px;
  max-height: 300px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}

.center {
  transform: scale(1.5);
  margin: 6.2rem;
  opacity: none;
}

.opacity {
  opacity: 0.7;
}

.end {
  transform: scale(0.6);
  margin: 20px;
}

button {
  font-size: 24px;
  padding: 5px 10px;
  margin: 10px;
}

@media (max-width: 1024px) {
  .drive1_img {
    width: 300px;
    height: 160px;
  }

  .drive2_img {
    width: 250px;
    height: 60px;
  }

  .drive3_img {
    width: 150px;
    height: 60px;
  }

  .drive2_img_move {
    bottom: -316px !important;
    right: -157px !important;
  }

  .drive3_img_move {
    bottom: -284px !important;
    right: -6px !important;
  }
}

@media (max-width: 425px) {
  .order-lg-1 {
    order: 2;
  }

  .order-lg-2 {
    order: 1;
  }

  .backgroundfeaturs_image {
    height: 254px;
    border-radius: 15px;

  }

  .featuresparaheading {
    font-size: 16px;
  }

}

@media (max-width: 768px) {
  .backgroundfeaturs_image {
    border-radius: 15px;
  }

  .drive1_img {
    width: 270px !important;
  }

  .drive2_img {
    width: 153px !important;
  }

  .drive2_img_move {
    bottom: -211px !important;
    right: -119px !important;
  }

  .drive3_img {
    width: 94px !important;
    height: 100% !important;
  }

  .drive3_img_move {
    bottom: -188px !important;
    right: -13px !important;
  }
}

@media (max-width: 430px) {
  .backgroundfeaturs_image {
    height: 260px;
  }
}

@media (max-width: 540px) {
  .backgroundfeaturs_image {
    height: 340px;
  }
}

@media (max-width: 430px) {
  .backgroundfeaturs_image {
    height: 280px;
  }
}

/* model --------------------- */
.modal-content {
  max-height: 100%;
  /* overflow: hidden; */
  /* width: 600px !important; */
}

.buttonfunctionaity_model {
  background-color: #2FB95D !important;
  color: #FFFFFF !important;
  width: 173px;
  height: 44px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  border-radius: 30px !important;
  border: none;
}

.comingsoon_model {
  font-size: 30px;
  font-family: Mulish;
  color: #2FB95D;
  font-weight: 700;
}

.paramodel {
  font-size: 18px;
  font-family: Mulish;
  font-weight: 600;
  color: #2c3e50;
}

.slider-container_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Set a specific height for the container */
}

.slider-container_mobile img {
  max-width: 100%;
  /* Ensure the image does not exceed the container width */
  max-height: 100%;
  /* Ensure the image does not exceed the container height */
  margin: 0 auto;
  /* Center the image horizontally */
  display: block;
  /* Remove any default inline spacing */
}

/* Add this CSS to your stylesheet or component */

.active-drawer-link {
  color: #2FB95D !important;
  font-weight: bold !important;
}

.css-10hburv-MuiTypography-root {
  font-weight: 600 !important;
}

/* StarRating.css */

/* product details ---------------------------------------- */
.productdetailsheading {
  font-size: 30px;
  font-family: Mulish;
  font-weight: 700;
  color: #043A3A;
}

.productdetailspara {
  font-size: 20px;
  font-family: Mulish;
  font-weight: 500;
  color: #043A3A;
}

.checkbox_iotdeatails {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.card_iotproductdetails {
  border-radius: 50px !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.technicaldetailshead {
  font-size: 18px;
  font-weight: 700;
  color: #043A3A;

}

.technicaldetailssubhead {
  font-size: 18px;
  font-weight: 500;
  color: #043A3A;

}

.floatingBtn_whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: rgba(64, 195, 81, 1);
  border-radius: 50%;
  font-size: 28px;
  color: white;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 20px;
  right: 50px;
}

.autoswitchdolimg {
  width: 380px;
  height: 380px;
}

.gsmmotorcontrollerimg {
  width: 1400px;
  height: 1400px;
}

.image-container-productdetails {
  overflow-x: auto; 
  overflow-y: hidden; 
}

.image-container-productdetails::-webkit-scrollbar {
  display: none;
}


.iotmobilestarterimg {
  width: 500px;
  height: 500px;
}
div#outlined-select-reason {
  text-align: start !important;
}
div#outlined-select-product {
  text-align: start !important;
}


@media (max-width: 425px) {
  .iotbackgroundimg {
    width: 300px !important;
    height: 300px !important;
    border-radius: 37.2px !important;
  }

  .modal-content {
    max-height: 100%;
    /* overflow: hidden; */
    /* width: 90% !important; */
  }
  /* .image-container-productdetails {
    max-height: 200px !important;
} */
}

@media (max-width: 1024px) {
  .technicaldetailshead {
    font-size: 14px;
  }

  .technicaldetailssubhead {
    font-size: 14px;
  }

  .iotbackgroundimg {
    width: 462px;
  }

  .contactcardparaheading {
    font-size: 16px !important;
  }

  .careercardsubheading {
    font-size: 14px !important;
  }

  .socialmediabackground_c {
    height: 47vh !important;
    width: 80px;
  }

  .iotfarmwhether_img {
    max-width: 638px !important;
  }

  .gsmmotor_cotroller_img {
    max-width: 732px !important;
  }

  .gsmmotor_cotroller_back {
    width: 385px !important;
    height: 385px !important;
    border-radius: 30px !important;
  }

  .auto-switch-dol_img {
    max-width: 530px !important;
  }

  .auto-switch-dol_background {
    width: 330px !important;
    height: 330px !important;
    border-radius: 30px !important;
  }

  .solar-insect-trapper_img {
    max-width: 630px !important;
  }

  .solar-insect-trapper_back {
    width: 390px !important;
    height: 390px !important;
    border-radius: 30px !important;
  }

  .iot-mobile-starter-img {
    max-height: 530px !important;
  }
  .twitter_logo{
    width: 15px !important;
    height: 15px !important;
    margin-bottom: 3px !important;
  }
  .selectedimage_resp{
    width: 311px !important;
  }
  .image-container-productdetails{
    width: 300px !important;
    max-width: 300px !important;
  }
}

@media (max-width: 768px) {
  .twitter_logo{
    width: 10px !important;
    height: 10px !important;
    margin-bottom: 0px !important;
  }
  .technicaldetailshead {
    font-size: 12px;
  }

  .technicaldetailssubhead {
    font-size: 12px;
  }

  .productdetailsheading {
    font-size: 22px;
  }

  .contactcardparaheading {
    font-size: 12px !important;
  }

  .careercardsubheading {
    font-size: 8px !important;
  }

  .iotfarmwhether_img {
    max-width: 300px !important;
  }

  .iotfarmwhether_background {
    width: 182px !important;
    height: 182px !important;
    border-radius: 20px !important;
  }

  .farm-iot-data-logger_img {
    max-width: 165px !important;
  }

  .gsmmotor_cotroller_img {
    max-width: 530px !important;
  }

  .gsmmotor_cotroller_back {
    width: 285px !important;
    height: 285px !important;
    border-radius: 30px !important;
  }

  .solar-fence-energizer-img {
    max-width: 208px !important;
  }

  .auto-switch-dol_img {
    max-width: 400px !important;
  }

  .auto-switch-dol_background {
    width: 260px !important;
    height: 260px !important;
    border-radius: 30px !important;
  }

  .solar-insect-trapper_img {
    max-width: 480px !important;
  }

  .solar-insect-trapper_back {
    width: 300px !important;
    height: 300px !important;
    border-radius: 30px !important;
  }

  .iot-mobile-starter-img {
    max-height: 400px !important;
  }

  .iot-mobile-starter-back {
    width: 360px !important;
    height: 360px !important;
    border-radius: 30px !important;
  }
}

@media (max-width: 425px) {
  .contactcardparaheading {
    font-size: 22px !important;
  }

  .careercardsubheading {
    font-size: 20px !important;
  }

  .iotfarmwhether_img {
    max-width: 310px !important;
  }

  .gsmmotor_cotroller_img {
    max-width: 283px !important;
  }

  .gsmmotor_cotroller_back {
    width: 200px !important;
    height: 200px !important;
    border-radius: 10px !important;
  }

  .auto-switch-dol_img {
    max-width: 280px !important;
  }

  .auto-switch-dol_background {
    width: 170px !important;
    height: 170px !important;
    border-radius: 10px !important;
  }

  .solar-insect-trapper_img {
    max-width: 278px !important;
  }

  .solar-insect-trapper_back {
    width: 170px !important;
    height: 170px !important;
    border-radius: 10px !important;
  }

  .iot-mobile-starter-img {
    max-height: 220px !important;
  }

  .iot-mobile-starter-back {
    width: 170px !important;
    height: 170px !important;
    border-radius: 10px !important;
  }

  span.readmore_blog.text-center.mt-2 {
    font-size: 10px;
  }
}

@media (max-width: 414px) {
  .mapcomp {
    height: 30vh !important;
  }

  .socialmediabackground_c {
    height: 23vh !important;
  }
}


/* Product --------------------------------------- */
.product_subheading {
  font-family: Mulish;
  font-size: 28px;
  font-weight: 700;
  color: #043A3A;
}

.product_subheading_color {
  font-family: Mulish;
  font-size: 28px;
  font-weight: 700;
  color: #2FB95D;
}

.custom-text-field {
  color: #2FB95D !important;
  /* Set your desired color here */
  /* Add any additional styles as needed */
}

.submitreview_contactus {
  /* width: 753px !important; */
  height: 50px !important;
  border-radius: 30px !important;
  background: #2FB95D !important;
  border: 1px solid #2FB95D !important;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.12);
  border: none;
  font-size: 24px !important;
  color: #FFF !important;
  font-family: Mulish;
}

.card-with-shadow-portfolio {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px !important;
}

.portfolio-fixed-card-body {
  height: 200px;
  overflow: auto;
}

.contactcardparaheading {
  font-size: 22px;
  font-weight: 500;
  font-family: Mulish;
  color: #043A3A;
}

.card_contactus_contactform {
  box-shadow: 0px 0px 10px rgba(41, 21, 21, 0.2);
  border-radius: 50px !important;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.socialmediabackground_c {
  width: 100px;
  height: 49vh;
  border-radius: 0px 30px 30px 0px;
  background-color: #2FB95D;
}

.css-bz6wus-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2FB95D !important;
  border-width: 1px !important;
}

.text-area_contact {
  border-color: #2FB95D !important;
  border: 1px solid #2FB95D !important;
}

.error-message {
  color: red;
  font-weight: 500;
  font-size: 12px;
}

.billing_details {
  color: #9FAFA4 !important;
}

/* blogdetails .................. */
.blogdetailsTopimg {
  border-radius: 50px;
}

.blogdetaildatehead {
  font-size: 20px;
  font-family: Mulish;
  color: #043A3A;
  font-weight: 300;
}

.vertical-line {
  border-left: 1px solid black;
  height: 20px;
  margin: 0 10px;
}

.icon_allblog {
  font-size: 1.4em;
  margin: 0 5px;
}

.blogdetailall {
  font-size: 20px;
  font-family: Mulish;
  color: #2FB95D;
  font-weight: 600;
}

.underlineGreen:hover {
  /* text-decoration: underline !important; */
  /* text-decoration-color: #2FB95D !important; */
  border-bottom: 1px solid #2FB95D !important;
}
.like_text{
  font-size: 16px;
  font-family: Mulish;
  font-weight: 600;
  color: #043A3A;
}

.in_stock {
  padding: 2px;
  color: #2FB95D;
  font-size: 14px;
  border-radius: 5px;
  background-color: #DBFFE7;
}

.percent_off {
  padding: 4px 10px 4px 10px;
  color: #FFFFFF;
  border-radius: 5px;
  background-color: #cc0c39;
  font-size: 14px;
}
.percent_off_onsale {
  padding: 4px 10px 4px 10px;
  color: #FFFFFF;
  border-radius: 5px;
  background-color: orange; /* Change background color to orange */
  font-size: 14px;
}


.cardproduct_new {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  /* Adjust values as needed */
}

.custom-modal-header {
  border-bottom: none !important;
  /* Use !important to override default styles */
}

.shareblogdetails {
  font-size: 18px !important;
}

.shareicon_blogdetails {
  background-color: #FFFFFF;
  border-radius: 50%;
  padding: 15px;
  font-size: 25px;
  box-shadow: 0px 0px 5px rgba(0.1, 0.1, 0.1, 0.1);
  cursor: pointer;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  /* Ensures container takes full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: Add a semi-transparent background */
}

/* Product new ----------------------------------------- */
.fullprice_product {
  font-size: 16px;
  font-weight: 600;
  color: #9FAFA4;
  font-family: Mulish;
}

.actualprice_product {
  font-size: 32px;
  font-weight: 700;
  color: #2FB95D;
  font-family: Mulish;
}

.percentoff_product {
  font-size: 16px;
  font-weight: 600;
  color: #2FB95D;
  font-family: Mulish;
}

.downarrow {
  font-size: 12px !important;
}

.percentoff_product_downarrow {
  color: #2FB95D;
}

.product_name {
  font-size: 18px;
}
.checked-half {
  position: relative;
}






@media (max-width: 425px) {
  .product-card {
    flex-direction: column;
    text-align: center;
  }
  .twitter_logo{
    width: 10px !important;
    height: 10px !important;
    margin-bottom: 0px !important;
  }

  .product_name {
    font-size: 14px;
  }

  .percent_off {
    padding: 2px 4px 2px 4px;
    color: #FFFFFF;
    border-radius: 4px;
    background-color: #cc0c39;
    font-size: 14px;
  }
  
}
/* cart------------------------------------------------------------- */
.button-cart {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* Adjust the values as needed */
}

.cart-count {
  background-color: red;
  /* or any other color */
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  position: absolute;
  top: 4;
  right: 4;
}

.blue-button-cart {
  background-color: #34495E !important;
  border: none;
  color: white;
}

.custom-table-body {
  width: 100%;
  height: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-table-body tr {
  margin-bottom: 10px !important;
}

.custom-table-head {
  width: 100%;
  height: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.table>:not(:last-child)>:last-child>* {
  border-bottom: none;
}

.button-container {
  display: flex;
  align-items: center;
}

.round-button {
  border-radius: 30%;
  background-color: #fff;
  color: black;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin: 0 5px;
}
.badge{
    --bs-badge-padding-x: 0.45em !important;
    --bs-badge-padding-y: 0.25em !important;
}
.buynow_productheading{
  font-size: 13px !important;
  font-family: Mulish;
  font-weight: 600;
}
.image-thumbnail {
  border: 1px solid #2FB95D; /* Black border */
  border-radius: 20px; /* Rounded corners */
  width: 100%; /* Ensure all images take up full width */
  height: auto; /* Maintain aspect ratio */
}
.product-name-container {
  max-width: 200px; /* Adjust the width as per your layout */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.backgroundperoff{
  background-color: #2FB95D !important;
}
